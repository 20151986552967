
(function ($) {
  "use strict";

  /*----------------------------
   jQuery MeanMenu
  ------------------------------ */
  jQuery('nav#dropdown').meanmenu();

  /*----------------------------
   wow js active
  ------------------------------ */
  new WOW().init();

  /*----------------------------
   Testimonial active
  ------------------------------ */
  $(".client-testimonial-area").owlCarousel({
    autoPlay: true,
    slideSpeed: 500,
    pagination: false,
    navigation: false,
    items: 1,
    /* transitionStyle : "fade", */    /* [This code for animation ] */
    navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    itemsDesktop: [1199, 1],
    itemsDesktopSmall: [980, 1],
    itemsTablet: [768, 1],
    itemsMobile: [479, 1],
  });
  /*----------------------------
   Portfolio active
  ------------------------------ */
  $(".image-slider").owlCarousel({
    autoPlay: true,
    slideSpeed: 500,
    pagination: false,
    navigation: true,
    items: 1,
    /* transitionStyle : "fade", */    /* [This code for animation ] */
    navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    itemsDesktop: [1199, 1],
    itemsDesktopSmall: [980, 1],
    itemsTablet: [768, 1],
    itemsMobile: [479, 1],
  });
  /*----------------------------
   Partner Logo
  ------------------------------ */
  $(".client-logo").owlCarousel({
    autoPlay: true,
    slideSpeed: 500,
    pagination: false,
    navigation: true,
    items: 5,
    /* transitionStyle : "fade", */    /* [This code for animation ] */
    navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    itemsDesktop: [1199, 5],
    itemsDesktopSmall: [980, 3],
    itemsTablet: [768, 2],
    itemsMobile: [479, 1],
  });
  /*----------------------------
   Relateed Team Member
  ------------------------------ */
  $(".related-team").owlCarousel({
    autoPlay: true,
    slideSpeed: 500,
    pagination: false,
    navigation: true,
    items: 3,
    /* transitionStyle : "fade", */    /* [This code for animation ] */
    navigationText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    itemsDesktop: [1199, 3],
    itemsDesktopSmall: [980, 2],
    itemsTablet: [768, 1],
    itemsMobile: [479, 1],
  });

  /*------------------------------------
  search expand activation code
  -------------------------------------*/
  $("a> i.search-button").on('click', function () {
    $(".search-text").slideToggle('slow');
  });

  /*------------------------------------
  SideSlide menu Activation
  -------------------------------------*/
  $('#slideBotton').on('click', function () {
    $('#sideSlide').addClass("highlight");
  });
  $('.close').on('click', function () {
    $('#sideSlide').removeClass("highlight");
  });


  // /*--------------------------
  //  scrollUp
  // ---------------------------- */
  // $.scrollUp({
  //   scrollText: '<i class="fa fa-angle-up"></i>',
  //   easingType: 'linear',
  //   scrollSpeed: 900,
  //   animation: 'fade',

  // });


  /*--------------------------
   Sticky Menu Activation Code
  ---------------------------- */
  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 100) {
      $('.sticky').addClass('stick');
    } else {
      $('.sticky').removeClass('stick');
    }
  });
  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 100) {
      $('.mob-sticky').addClass('mob-stick');
    } else {
      $('.mob-sticky').removeClass('mob-stick');
    }
  });
  // /*----------------------------
  // mixitup active
  // ------------------------------ */
  // $('#Container').mixItUp();

})(jQuery);

